.section-temoin {

    margin-top: 50px;
    padding-bottom: 0px;

    .contenu-text {
        h4 {
            font-family: workSans-Bold;
            font-size: 22px;
        }

        p {
            font-size: 16px;
            font-family: workSans-Light;
            color: $blue-medium;
        }
    }

    background-color: $guyabano;

    .temoin {
        h2 {
            font-family: 'workSans-Bold';
            color: rgb(51, 51, 51);
            font-weight: 700;
            font-size: 30px;
            margin-top: 0px;
            margin-bottom: 0px;
            line-height: 1.41;
            @media screen and (max-width: 991px) {
                font-size: 25px;
            }
        }
    }

}

.section-parcours{
    padding-bottom: 84px;
    background: $white;
    h2{
        font-family: 'Cerebris-Bold';
        color: $blue-medium;
        font-weight: 700;
        font-size: 40px;
        margin-top: 89px;
        margin-bottom: 49px;
        line-height: 1.41;
        @media screen and (max-width: 991px) {
            font-size: 25px;
        }
    }

    p{
        margin-bottom: 84px;
    }
}

.contenu-text-job {
    margin-right: 45px;
    margin-left: 45px;
}
