.reglement {
    font-size: 18px;
    font-family: 'Cerebris-Light';

    h1{
        font-family: 'Cerebris';
    }

    @media screen and (max-width: 991px) {
        font-size: 12px;
        h1 {
            font-size: 1.5rem;
        }
    }
}

.subtitle {
    font-family: 'Cerebris';
    font-size: 20px;
    @media screen and (max-width: 991px) {
        font-size: 16px;
    }
}
