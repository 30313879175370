// Colors
$blue: #00e5ff;
$blue-medium: #1293ff;
$deep-blue: #156AFE;
$dark-blue: #1a2052;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #D34C3D;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: white;
$beige: rgb(244 239 233);
$dimgray: #4d4d4d;
$gray: #ABABAB;
$guyabano: #F8F8F8;
$black: #333333;
$beige: #f4efe9;
