@font-face {
    font-family: "Cerebris-Bold";
    src: url("/fonts/CerebriSansPro-Bold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Cerebris-Light";
    src: url("/fonts/CerebriSansPro-Light.ttf") format('truetype');
    font-style: normal;
}


@font-face {
    font-family: "Cerebris";
    src: url("/fonts/CerebriSansPro-Medium.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: "workSans-Bold";
    src: url("/fonts/worksans/WorkSans-ExtraBold.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: "workSans-Light";
    src: url("/fonts/worksans/WorkSans-Light.ttf") format('truetype');
    font-style: normal;
}


.padding-1 {
    padding-top: 50px;
    padding-bottom: 50px;
    @media screen and (max-width: 991px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.padding-2 {
    padding-top: 150px
}

.padding-3 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-4 {
    padding-top: 120px;
    padding-bottom: 50px;
}

.padding-5 {
    padding-right: 100px;
    @media screen and (max-width: 991px) {

        padding-right: 15px;
    }
}

.padding-6 {
  //  padding-left: 100px;
    @media screen and (max-width: 991px) {

        padding-left: 30px;
        padding-right: 30px;
    }
}
.padding-7 {
    padding-top: 90px;
}


.margin-1 {
    margin-bottom: 100px;
}

.margin-2 {
    margin-top: 80px;
    @media screen and (max-width: 991px) {
        margin-top: 0px;
    }
}

.margin-3 {
    margin-top: 0px;
    @media screen and (max-width: 991px) {
        margin-top: 80px;
    }
}

body {
    background-color: $guyabano;

}

.bg-dark-blue{
    background-color: $dark-blue;
}
.partenaire-slide{
    height: 120px;
    img{
        height: 120px;
    }
}
