.reunix-footer-top {
    font-family: 'workSans-Light';
    font-size: 15px;
   padding-left: 30px;
    img {
        width: 160px;
    }

    ul {
        list-style: none;
        padding-left: 0px;
    }

    a {
        color: $dimgray;
        font-weight: 400;

    }
    a:hover{
        text-decoration: none;
        color: $blue;
    }

    h6 {
        font-size: 18px;
        padding-top: 20px;
        text-decoration: none;
    }

}

.reunix-footer-bottom {
    font-family: 'Cerebris';
}
.social{
    color: $dimgray;
    display: inline-block;
    width: 48px;
    text-align: center;
    line-height: 46px;
    background: transparent;
    border: 1px solid rgb(237, 237, 237);
    border-radius: 50%;

    img{
        height: 41px;
    }

}
h6{
    font-family: 'workSans-Bold';
}






