.home-header {
    z-index: 1;
    background-image: url('/images/accueil/background-bandeau-reunix.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;

    @media screen and (max-width: 991px) {
        top: 82px;
    }

    p {
        font-size: 28px;
        margin-left: 6px;
        color: $white;
        font-family: "workSans-Bold";
        @media screen and (max-width: 1440px) {
            padding-bottom: 0px;
            font-size: 15px;
        }
        @media screen and (min-width: 721px) and (max-width: 991px) {
            font-size: 15px;
            margin-bottom: 0px;
        }

        strong {
            color: $blue;
            font-size: 1.5rem;
            font-family: "workSans-Bold";
        }
    }

    h2 {
        font-size: 4rem;
        color: $blue;
        padding-bottom: 40px;
        font-family: "workSans-Bold";
        @media screen and (max-width: 1440px) {
            padding-bottom: 0px;
            padding-top: 20px;
            font-size: 2rem;
        }
        @media screen and (min-width: 721px) and (max-width: 991px) {
            font-size: 1.75rem;

        }
    }

    img {
        @media screen and (max-width: 991px) {
        }
    }


}

.partenaire {
    font-family: 'Cerebris';
    background-color: $white;
    height: fit-content;

    h2 {
        text-align: center;
        font-size: 14px;
        color: $gray;
    }

    @media screen and (max-width: 991px) {
        margin-top: 80px;
    }
}


.job {
    font-family: 'Cerebris';
    text-align: -webkit-center;
    background-color: $guyabano;

    h3 {
        text-align: center;
        font-size: 14px;
        color: $gray;
        padding-bottom: 50px;
        font-weight: 400;
        margin-top: -5px;
        text-transform: uppercase;
        letter-spacing: 3px;
        line-height: 1.43;

    }
}

.work {
    font-family: 'Cerebris';
    text-align: center;
    background-color: $white;
    padding: 50px 30px 63px;
    height: 300px;
    box-shadow: rgb(51 51 51 / 10%) 0px 18px 40px;
    @media screen and (max-width: 460px) {
        height: 220px;
    }

    h2 {
        text-align: center;
        font-size: 14px;
        color: $gray;
    }

    h4 {
        text-align: center;
        font-size: 24px;
        color: $black;
    }

    p {
        font-size: 15px;
        color: $dimgray;

        @media screen and (max-width: 991px) {
            font-size: 10px;
        }

    }

    img {
        padding-bottom: 41px;
    }


}

.slick-initialized .slick-slide {
    display: block;
    text-align: -webkit-center;

}

.slick-next:before {
    content: '→';
    background-color: black;
}

.bottom-home {
    height: fit-content;
    background-color: $dark-blue;
    font-family: 'workSans-Light';

    h3 {
        font-family: 'workSans-Bold';
        font-size: 40px;
        color: $white;
        @media screen and (max-width: 991px) {
            font-size: 25px;
        }
    }

    p {
        font-size: 15px;
        color: $white;
    }

    span {
        color: $blue;
    }

    .number {
        font-family: 'workSans-Bold';
        font-size: 48px;
        color: $blue;

        @media screen and (max-width: 991px) {
            font-size: 25px;
        }
    }
}

.blue {
    color: $blue;
}

#metier {
    .contenu-text {
        p {
            a {
                color: $dimgray;
                font-family: 'workSans-Bold';
            }
        }

    }

    h2 {
        margin-top: 89px;
        margin-bottom: 49px;
    }
}

html{
    /* chrome et firefox not supporter scroll brutalement */
   scroll-behavior: smooth;
}

//content-text uniquement sur accueil
.home-page {

    .position-absolute {
        bottom: 0;
        right: 30;
    }
    .bloc-header{
        max-width: 1920px;
        margin: auto;
    }
    .bloc-text-header {
        margin: 121px 0px;
        max-width: 1181px;
    }
    .bloc-header-img{
        max-width: 670px;

    }

    //margin header home page

    @media screen and (min-width: 1440px) {

        .bloc-text-header {
            padding-left: 205px;

        }

    }

    @media screen and (max-width: 768px) {

        .home-header {
            .bloc-header {
                justify-content: center !important;
            }

        }

        .bloc-text-header {
            padding-left: 25px;
            flex: 0 0 100% !important;
            max-width: 100% !important;


        }


    }

    .metier {
        margin-top: 50px;
        padding-bottom: 100px;

        .contenu-text {
            h4 {
                font-family: workSans-Bold;
                font-size: 22px;
            }

            p {
                font-size: 16px;
                font-family: workSans-Light;
                color: $blue-medium;
            }
        }
    }

    .icon-plus {
        height: 28px;
        margin-right: 5px;
        margin-top: 6px;
    }

    .btn-dark {
        background-color: $dark-blue;
        border: $dark-blue;
        border-radius: 2rem;
        border-radius: 2rem;
        padding: 7px 26px;
        font-size: 28px;
        text-transform: uppercase;
        margin: 0px 13px 13px 0px;
    }

    .btn-dark:hover {
        background-color: $dark-blue;
        border: $dark-blue;
    }

    .bloc-button {
        margin: 32px 0px;
    }
    .row-contenu {
        max-width : 1920px;
        margin: auto;
    }

    .row .contenu {
        .col-12 + .col-12 {
            padding-right: 0px;
        }

    }

    .row-metier {
        margin: 0px 120px;
    }

    .contenu-img {
        text-align: right;
        margin-bottom: 15px;
        img{
            height: 123px;
        }
    }

    @media screen and (max-width: 768px) {


        .row-metier {
            margin: 0px;
        }

        .contenu-img {
            text-align: center;
        }

        .bloc-header-img {
            display: none!important;
        }

        .bloc-button{
            width: min-content;
            padding: 0px;
        }

        .bloc-reunix{
            justify-content: inherit!important;
        }
        .max-width-reunix-question{

            min-width: fit-content;
        }

        .max-width-reunix-service{
            min-width: fit-content;
        }

        .contenu-text-job{
            margin: 0px;
        }

        .bloc-contenu-img{
            align-items: center;
            justify-content: center;
        }

    }
    @media screen and (min-width: 769px) and (max-width: 1440px) {
        .bloc-text-header {
            padding-left: 25px;
        }
        .contenu-img{
            padding: 0px!important;
            img{
                height: 90px;
            }
        }

    }

    @media screen and (max-width: 1850px)  {
        .bloc-header-img {
            display: none!important;
        }
    }

    .max-width-reunix-question{
        max-width: 628px;
        margin-right : 28px;
    }
    .max-width-reunix-service{
        max-width: 636px;
    }
}
