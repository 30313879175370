.section-contenu {
    background-color: $dark-blue;
}

.about {
    .section-contenu {
        background-color: $white;
    }
}

.contenu-header {
    z-index: 1;
    background-color: $beige;
    width: 100%;
    font-family: 'workSans-Bold';
    position: relative;
    top: 45px;
    height: 549px;

    .link-text {
        font-family: 'workSans-Light';
        font-size: 14px;
        color: $white;
        text-decoration: none;
        background-image: linear-gradient($beige, $beige),
        linear-gradient($blue, $blue);
        background-size: 100% 2px, 0 2px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 0.5s linear;

        &:hover {
            text-decoration: none;
            color: $blue;
            background-size: 0 2px, 100% 2px;

        }
    }

    h1, h2 {
        font-size: 56px;
        color: $blue;
        padding-top: 15px;
        padding-bottom: 15px;

        @media screen and (max-width: 991px) {
            font-size: 32px;
        }
    }

    p {
        font-family: 'workSans-Light';
        font-size: 24px;
        color: $white;
        @media screen and (max-width: 991px) {
            font-size: 20px;
        }
    }

    a {
        color: $white;
    }

    @media screen and (max-width: 991px) {
        height: 411px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
    }
}

.contenu {

    text-align: -webkit-center;
    padding: 0px;

    h1, h2, h3 {
        font-family: 'workSans-Bold';
        text-align: center;
        color: $blue-medium;
        font-weight: 700;
        font-size: 40px;
        margin-top: 0px;
        margin-bottom: 0px;
        line-height: 1.41;
        @media screen and (max-width: 991px) {
            font-size: 25px;
        }
    }

    p {
        font-family: 'workSans-Light';
        font-size: 16px;
        color: rgb(105, 105, 105);
        text-align: left;
    }

    span {
        color: $blue-medium;
    }
}

.contenu-text {
    color: rgb(105, 105, 105);
    font-size: 15px;
    line-height: 1.67;
    padding-bottom: 30px;
    text-align: left;

    span {
        color: $blue;
    }
}

.contenu-img {
    max-width: 123px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 0px;

}

.img-locaux {
    width: 74%;
    height: auto;
}

.h70 {
    height: 70px;
}

.contenu-text-job {
    max-width: 533px;
    margin-right: 90px;
}
