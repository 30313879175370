.equipe-gallerie {

    img {
        padding-bottom: 20px;
    }
    h2{
        color : $blue!important;
    }
    h4{
        color: $white;
    }
    h3 {
        font-family: 'workSans-Bold';
        font-size: 24px;

    }

    p {
        font-family: 'workSans-Light';
        font-size: 15px;
        color: $white;
    }

}

.section-patchwork {
    img {
        padding: 10px;
    }
}

.about{

    .contenu-header{
        a{
            color : $white;
        }
    }
    .equipe-gallerie {
        h2 {
            font-family: "Cerebris-Bold";
            text-align: center;
            color: #333333!important;
            font-weight: 700;
            font-size: 40px;
            margin-top: 0px;
            margin-bottom: 0px;
            line-height: 1.41;

            span {
                color: #23a3e0!important;
            }

        }

        p {
            font-family: 'workSans-Light';
            font-size: 15px;
            color: #696969;
        }

    }



}
