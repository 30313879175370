// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@800&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'nav';
@import 'footer';
@import 'layout';
@import 'accueil';
@import 'contact';
@import 'contenu';
@import 'equipe';
@import 'engagements';
@import 'reglement';





