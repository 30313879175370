.nav-reunix {
    font-family: 'workSans-Bold';
    background-color: $white;
    display: flex;
    height: 120px;
    width: 100%;
    top: 0;
    z-index: 2;
    font-size: 16px;
    overflow: hidden;
    position: fixed;
    box-shadow: rgb(51 51 51 / 10%) 0px 18px 40px;
    align-content: center;

    img {
        width: 210px;
        padding: 30px;
        @media screen and (max-width: 991px) {
            width: 110.55px;
            padding: 10px;
        }
    }

    a.nav-link {
        margin-right: 25px;
        transition: all 250ms;
        padding: 29px 0;
        text-decoration: none;
        background-image: linear-gradient($white, $white),
        linear-gradient($blue, $blue);
        background-size: 100% 2px, 0 2px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;

        &:hover, &.active {
            text-decoration: none;
            color: $blue;
            background-size: 0 2px, 70% 4px;

        }
    }

    a.nav-link-hover {
        margin-right: 25px;
        transition: all 250ms;
        padding: 29px 0;
        text-decoration: none;
        background-image: linear-gradient($white, $white),
        linear-gradient($blue-medium, $blue-medium)!important;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        &:hover, &.active {
            text-decoration: none;
            color: $blue-medium!important;
            background-size: 0 2px, 70% 4px;

        }


    }
}

.navbar-toggler {
    border: none;
    padding: 0px;
    margin-right: 16px;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: $blue;

}

.navbar-light .navbar-nav .nav-link {
    color: $black;
}


.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.5rem;
    padding-left: 0.5rem;
    font-family: 'workSans-Light';
}

/**** Side Bar *****/

.sidebar {
    height: 100%;
    width: 400px;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: $deep-blue;
    overflow-x: hidden;
    transition: 0.5s;

    img {
        width: 160px;
    }
}

.navbar-nav a {
    font-family: 'workSans-Bold';
    padding: 8px 8px 32px 32px;
    text-decoration: none;
    font-size: 16px;
    color: $white;
    display: block;
    transition: 0.3s;
}

.sidebar a:hover {
}

.sidebar .closebtn {
    top: 0;
    right: 25px;
    font-size: 36px;
    color: $black;
}

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: $black;
    color: white;
    padding: 10px 15px;
    border: none;
}

.openbtn:hover {
    background-color: #444;
}

.head-sidebar {
    background-color: $white;
    width: 100%;
}

#mySidebar {
    width: 0px;
}
