.contact-header {
    z-index: 1;
    background-color: $beige;
    width: 100%;
    font-family: 'Cerebris';
    position: relative;
    top: 80px;
    height: 410px;

    h1 {
        font-size: 46px;
        color: $blue;
        padding: 15px;
    }

    .link-text {
        font-family: 'Cerebris';
        font-size: 14px;
        color: $dimgray;
        text-decoration: none;
        background-image: linear-gradient($beige, $beige),
        linear-gradient($blue, $blue);
        background-size: 100% 2px, 0 2px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 0.5s linear;

        &:hover {
            text-decoration: none;
            color: $blue;
            background-size: 0 2px, 100% 2px;

        }
    }
}

.contact-form {
    background-color: $white;
    font-family: 'Cerebris-Light';

    h3 {
        font-family: 'Cerebris-Bold';
        font-size: 32px;
    }

    p {
        font-size: 14px;
        color: $dimgray;
    }
    span{
        color: $blue;
    }

    input, textarea {
        width: 100%;
        min-height: 56px;
        background-color: $guyabano;
        font-size: 15px;
        font-weight: 400;
        border-width: 1px;
        border-color: $white;
        border-style: solid;
        border-radius: 5px;
        padding: 0px 20px;
        color: rgb(105, 105, 105) !important;
    }

    textarea {
        padding: 15px 20px;
    }

    button {
        font-family: 'Cerebris-Bold';
        font-size: 15px;
        font-weight: 500;
        border-style: solid;
        border-color: transparent;
        padding: 0px 36px;
        text-align: center;
        border-width: 1px;
        transform: translateY(0px);
        border-radius: 5px;
        color: $white;
        background: $blue;
        line-height: 54px;
        height: 54px;
        margin-left: 7px;

        &:hover, active {
            color: $blue;
            background: white;
            border: none;

        }
    }

}
.center-button-contact{
    @media screen and (max-width: 991px) {
        justify-content: center;
    }}

.contact-map {
    background-color: $white;
}

.contact-success {
    z-index: 3;
    position: fixed;
    width: 100%;
}


.pipe::before {
    content: "";
    position: absolute;
    top: 30%;
    left: 0px;
    width: 4px;
    height: 60%;
    transform: translateY(-50%);
    background-color: $red;
}
.mandatory{

    input{
        width: 15px;
    }
}
